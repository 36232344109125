$ ->
#  $('#services').multiselect(
#    buttonWidth: '100%'
#    includeResetOption: true,
#    includeResetDivider: true
#    nonSelectedText: 'Select Services'
#  );
  $('#services').select2({
    placeholder: 'Select Services'
  });
  $("#phone").intlTelInput(
    initialCountry: 'auto'
    geoIpLookup: (callback) ->
      $.get('https://ipinfo.io', (->
      ), 'jsonp').always (resp) ->
        countryCode = if resp and resp.country then resp.country else ''
        callback countryCode
    utilsScript: "../bower_components/intl-tel-input/build/js/utils.js"
  )


  $('#contactForm').submit (event)->
    console.log('validation completed.');
    data = $('#contactForm').serializeArray()
    event.preventDefault();
    grecaptcha.execute();


window.evalCap = (token) ->
  data = $('#contactForm').serializeArray()
  console.log data

  services = $('#services option:selected');
  selected = [];
  $(services).each (index, service)->
    selected.push [$(this).val()]

  index = 0
  while index < data.length
    if data[index].name == 'phone'
      data[index].value = $("#phone").intlTelInput("getNumber")
      break
    ++index
  data.push({name: "services", value: selected.join(", ")})
  console.log data
  $.ajax(
    url: 'api/recaptcha.php'
    type: 'POST'
    data: data,
    dataType: "json"
    success: (data)->
      toastr.success(data.message)
      grecaptcha.reset()
      $('#contactForm')[0].reset();
    error: (error)->
      console.log error
      toastr.error(error.message)
      grecaptcha.reset()
  )


